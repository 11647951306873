

















import {Component, Vue} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class ConvertToTemplate extends Vue {
  loading: boolean = false;

  confirmConvert() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
      this.$emit('confirmed', true);
      this.$nextTick(function () {
        //@ts-ignore
        this.$parent.close();
      });
    }, 1200);
  }
}
