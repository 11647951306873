













































import { Component, Vue, Prop } from 'vue-property-decorator';
import AddBookmark from "@/components/bookmarks/AddBookmark.vue";
import {DeleteBookmarkMutation} from "@/graphql/bookmark";
import { BoardQuery } from '@/graphql/board';
import orderBy from "lodash/orderBy";
import Paywall from "@/components/subscription/Paywall.vue";

@Component({
  components: {AddBookmark},
})
export default class BoardBookmarks extends Vue {
  @Prop({ default: false }) isPremium!: boolean;
  @Prop({ default: false }) newUser!: boolean;
  @Prop({ default: false }) hasFreemiumAccess!: boolean;

  get board() {
    return this.$store.state.board;
  }

  get canAddBookmarks() {
    return (this.$store.state.workspace.currentSubscription && this.$store.state.workspace.currentSubscription.hasAccess && this.$store.state.workspace.currentSubscription.plan.planAccess.bookmark_access) || this.hasFreemiumAccess
  }

 

  exampleBookmarks = [
    { id: '1', name: 'Figma', url: 'https://www.figma.com' },
    { id: '2', name: 'Deep Work Ambient', url: 'https://open.spotify.com/playlist/1FvBji31SwAaVeKM0zSAyh' }
  ];

  deleteBookmark(bookmark_id: string) {
    this.$apollo
      .mutate({
        mutation: DeleteBookmarkMutation,
        variables: {
          id: bookmark_id,
        },
        refetchQueries: [{ query: BoardQuery, variables: { id: this.board.id } }],
      })
      .then(_ => {
        this.$buefy.toast.open({
          message: 'Bookmark deleted',
          position: 'is-bottom-right',
          type: 'is-black',
        });
      })
    ;
  }

  get bookmarksOrderByCreatedAt() {
    return orderBy(this.board.bookmarks, (item) => {
      return item.created_at
    }, ['desc']);
  }



  openPaywall() {
    this.$buefy.modal.open({
      component: Paywall,
      // @ts-ignore
      customClass: 'split-modal',
      parent: this,
    });
  }

  openBookmarks() {
    if(this.canAddBookmarks) {
      this.$buefy.modal.open({
        component: AddBookmark,
        width: '520px',
        props: {
          origin: 1,
          origin_id: this.board.id,
          inModal: true
        },
        parent: this,
      });
    } else {
      this.openPaywall();
    }
    
  }
}
