















































































































import {Component, Vue} from 'vue-property-decorator';
import gql from 'graphql-tag';
//@ts-ignore
import { Sketch } from 'vue-color';
import {BoardBackgroundImageQuery} from "@/graphql/BoardBackground";
import {BoardBackground} from "@/typescript/types";
import {BoardQuery} from "@/graphql/board";
import BoardPrivacy from "@/components/board/utilities/BoardPrivacy.vue";

@Component({
  components: {BoardPrivacy, 'sketch-picker': Sketch},
  apollo: {
    getBackgrounds: {
      query: BoardBackgroundImageQuery,
      result(result) {
        this.backgroundImages = result.data.getBackgrounds;
      }
    }

  },
})
export default class BoardInfoEdit extends Vue {
  backgroundImages: BoardBackground[]  = [];
  boardNameInput: string | null = null;
  boardDescriptionInput: string | null = null;
  submitError = false;
  successLoading = false;
  boardLevelInput: number | null = 2;

  nameCollapseOpen = false;
  descriptionCollapseOpen = false;
  boardlevelCollapseOpen = false;
  isDisabled: boolean = false;
  boardBGColorInput: string = '#FFFFFF';
  colorPickerOpened: boolean = false;
  boardBackgroundImage: string | null = null;
  allowBackgroundEdit: boolean = false;

  get board() {
    return this.$store.state.board;
  }

  updateBoard() {
    this.successLoading = true;
    this.isDisabled = true;

    this.$apollo
      .mutate({
        mutation: gql`
                    mutation updateBoard($id: ID!, $name: String!, $description: String, $board_level_identifier: Int, $sprint_interval: Int, $background_color: String, $board_background_id: ID) {
                        updateBoard(id: $id, data: { name: $name description: $description boardLevel: { identifier: $board_level_identifier } sprint_interval: $sprint_interval, background_color: $background_color, board_background_id: $board_background_id }) {
                            id
                            name
                            description
                            boardLevel {
                                identifier
                            }
                            sprint_interval
                            background_color
                        }
                    }
                `,
        variables: {
          id: this.board.id,
          name: this.boardNameInput,
          description: this.boardDescriptionInput,
          board_level_identifier: this.boardLevelInput,
          background_color: (this.boardBGColorInput != '#FFFFFF')? this.boardBGColorInput : null,
          board_background_id: this.boardBackgroundImage
        },
        refetchQueries:[{query: BoardQuery, variables:{pid: this.board.pid}}]
      })
      .then(_ => {
        //@ts-ignore ToDo: fix type issue
        this.$parent.close();
        this.$buefy.toast.open({
          message: 'Changes saved',
          position: 'is-bottom-right',
          type: 'is-black',
        });
      })
      .catch((_error) => {
        this.submitError = true;
        setTimeout(() => {
          this.submitError = false;
        }, 1200);
      })
      .finally(() => {
        this.descriptionCollapseOpen = false;
        this.nameCollapseOpen = false;
        this.successLoading = false;
      });
  }

  updateBackgroundColor(color: any){
    this.boardBGColorInput = color.hex;
  }

  getBackgroundLink(url: string) {
    //@ts-ignore
    return this.$attachmentsLinks.file_path['background'] + url;
  }

  setBoardBackground(id: string, event: any) {
    let allClasses = document.querySelectorAll('.is-fit-cover.active');
    for (let i = 0; i < allClasses.length; i++) {
      allClasses[i].classList.remove('active')
    }
    event.target.classList.add('active');
    if(id == 'no-bg'){
      this.boardBackgroundImage = null;
    }else{
      this.boardBackgroundImage = id;
    }

  }

  mounted() {
    this.boardNameInput = this.board.name;
    this.boardDescriptionInput = this.board.description;
    this.boardLevelInput = this.board.boardLevel.identifier;
    this.boardBGColorInput = (this.board.background_color) ? this.board.background_color : '#FFFFFF';
    this.boardBackgroundImage = (this.board.backgroundImage) ? this.board.backgroundImage.id : null;
    this.$nextTick(function(){
      //@ts-ignore ToDo: fix type issue
      this.$refs.nameEdit.focus();
    });
  }
}
