
























































import {Component, Vue, Prop} from 'vue-property-decorator';
import {BoardAttachments, DeleteAttachments} from "@/graphql/attachment";
import orderBy from "lodash/orderBy";
import {AttachmentPaginator, User} from "@/typescript/types";
import BoardAttachmentsModal from "@/components/board/BoardAttachments.vue";
import {WorkspaceQuery} from "@/graphql/workspace";
import Paywall from "@/components/subscription/Paywall.vue";


@Component({
  components: {},
  apollo: {
    boardAttachments: {
      query: BoardAttachments,
      fetchPolicy: 'network-only',
      variables() {
        return {
          board_id: this.board.id,
        };
      },
      update(result) {
        return result.boardAttachments;

      },
    },
  }
})
export default class BoardFiles extends Vue {

  @Prop({ default: false }) isPremium!: boolean;
  @Prop({ default: false }) currentPlan!: string;
  @Prop({ default: false }) newUser!: boolean;

  boardAttachments: AttachmentPaginator | null = null;
  boardAttachmentPage: number = 1;
  

  get board() {
    return this.$store.state.board;
  }

  get attachmentsPreview() {
    if(this.boardAttachments) {
      return orderBy(this.boardAttachments!.data, [(item) => item.created_at], ['desc']);
    }
    return [];

  }

  get boardAttachmentHasPages() {
    if(this.boardAttachments) {
      return this.boardAttachments.paginatorInfo.hasMorePages;
    }
    return false;
  }


  get me() {
    return this.$store.state.me;
  }


  get hasFreemiumAccess() {
    return this.me.has_freemium_access 
  }

  get canAddFiles() {
    return (this.$store.state.workspace.currentSubscription && this.$store.state.workspace.currentSubscription.hasAccess && this.$store.state.workspace.currentSubscription.plan.planAccess.file_manager_access) || this.hasFreemiumAccess;
  }



  get isOwner() {
    let owner = this.$store.state.board.owner.find((user: User) => user.id == this.$store.state.me.id);
    if (owner) {
      return true;
    }
    return false;
  }

  getAttachment(fileName: string, folder: string) {
    //@ts-ignore
    return this.$attachmentsLinks.file_path[folder] + fileName;
  }

  openAttachmentModal() {
    if(this.canAddFiles) {
      this.$buefy.modal.open({
        component: BoardAttachmentsModal,
        width: '480px',
        // @ts-ignore
        parent: this,
      });
    } else {
      this.openPaywall();
    } 
  }

  openPaywall() {
    this.$buefy.modal.open({
      component: Paywall,
      // @ts-ignore
      customClass: 'split-modal',
      parent: this,
    });
  }

  async downloadFile(url: string, name: string) {
    let blob = await fetch(url).then(r => r.blob());
    var fileURL = window.URL.createObjectURL(blob);
    var fURL = document.createElement('a');

    fURL.href = fileURL;
    fURL.setAttribute('download', name);
    document.body.appendChild(fURL);

    fURL.click();
  }

  fetchMoreBoardAttachments() {
    this.boardAttachmentPage++;
    // setTimeout(() => {
    //
    //   this.infiniteStateUp.loaded();
    // }, 5000);

    this.$apollo.queries.boardAttachments.fetchMore({
      variables: {
        page: this.boardAttachmentPage,
      },
      updateQuery: (previousResult, {fetchMoreResult}) => {
        //this.infiniteStateUp.loaded();
        // this.isLoading = false;
        return {
          boardAttachments: {
            __typename: previousResult.boardAttachments.__typename,
            /**
             * Merging the tag list
             */
            data: [...previousResult.boardAttachments.data, ...fetchMoreResult.boardAttachments.data],
            paginatorInfo: fetchMoreResult.boardAttachments.paginatorInfo,
          },
        };
      },
    });
  }

  deleteAttachment(attachment_id: string) {
    let index = this.boardAttachments!.data.findIndex((attachment: any) => attachment.id === attachment_id);

    if (index != -1) {
      this.boardAttachments!.data.splice(index, 1);
    }

    this.$apollo.mutate({
      mutation: DeleteAttachments,
      variables: {
        attachmentId: attachment_id
      },
      refetchQueries:[{query: WorkspaceQuery, variables:{pid: this.$store.state.workspace.pid}}]
    }).then(_ => {
      this.$buefy.toast.open({
        message: 'Deleted!',
        position: 'is-bottom-right',
        type: 'is-black',
      });
    })
  }

}
