















import {Component, Vue} from 'vue-property-decorator';
import AddOrLinkNorthStar from "@/components/northstars/AddOrLinkNorthStar.vue";

@Component({
  components: {AddOrLinkNorthStar},
})
export default class BoardNorthStars extends Vue {
  get board() {
    return this.$store.state.board;
  }

  openAddNorthStarModal() {
    //@ts-ignore
    this.$parent.close();
    this.$buefy.modal.open({
      component: AddOrLinkNorthStar,
      width: '480px',
      // @ts-ignore
      parent: this,
    });
  }
}
