











import {Component, Vue} from 'vue-property-decorator';
import BoardBookmarks from "@/components/board/utilities/BoardBookmarks.vue";
import BoardAttachments from "@/components/board/BoardAttachments.vue";
import RoomCenterStage from "@/components/room/RoomCenterStage.vue";

@Component({
  components: {RoomCenterStage, BoardAttachments, BoardBookmarks},
})
export default class BoardShared extends Vue {

}
