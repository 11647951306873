





















































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import gql from 'graphql-tag';
import ShareUrl from '@/components/board/ShareUrl.vue';
import InlineDropdown from "@/components/general/InlineDropdown.vue";

@Component({
  components: {InlineDropdown, ShareUrl },
})
export default class BoardPrivacy extends Vue {
  editPrivacyActive: boolean = false;
  boardPublicityStatus: string | null = null;
  clicked = false;

  @Watch('board')
  onBoardChange() {
    this.boardPublicityStatus = this.board.permissions
  }

  @Prop({default: false, required: false})
  open: boolean | null | undefined;

  get myRole() {
    return this.$store.state.board.my_role;
  }

  get board() {
    return this.$store.state.board;
  }

  get me() {
    return this.$store.state.me;
  }

  get workspace() {
    return this.$store.state.workspace;
  }

  closeFromParent() {
    this.$emit('close', true);
  }

  togglePrivacy() {
    if(this.boardPublicityStatus === 'workspace') {
      this.boardPublicityStatus = 'private';
    } else {
      this.boardPublicityStatus = 'workspace';
    }
    this.updateBoard();
  }

  closeModal() {
    // @ts-ignore
    this.$parent.close() 
  }

  updateBoard() {
    this.clicked = true;
    this.$apollo
      .mutate({
        mutation: gql`
                    mutation updateBoard($id: ID!, $permissions: String) {
                        updateBoard(id: $id, data: { permissions: $permissions }) {
                            id
                            permissions
                        }
                    }
                `,
        variables: {
          id: this.board.id,
          permissions: this.boardPublicityStatus,
        },
      })
      .catch((_error) => {
      
      })
      .then(_ => {
        //@ts-ignore ToDo: fix type issue
        this.clicked = false;
        this.$buefy.toast.open({
          message: 'Changes saved!',
          position: 'is-bottom-right',
          type: 'is-black',
        });
      })
      .finally(() => {
      });
  }

  mounted() {
    this.boardPublicityStatus = this.board.permissions;
    if(this.open) {
      this.editPrivacyActive = true;
    }
  }
}
