























import { Component, Vue } from 'vue-property-decorator';
import AddOrLinkNorthStar from "@/components/northstars/AddOrLinkNorthStar.vue";
import NorthStarDetailed from "@/components/northstars/NorthStarDetailed.vue";
import { NorthStar, Board as BoardObject } from "@/typescript/types";
import NorthStarData from "@/components/northstars/NorthStarData.vue";
import BoardGoal from "@/components/board/utilities/BoardGoal.vue";
import WorkableItem from "@/components/general/WorkableItem.vue";
import NorthstarObjectiveLists from "@/components/objectives/NorthstarObjectiveLists.vue";

@Component({
  components: {WorkableItem, BoardGoal, NorthStarData, AddOrLinkNorthStar, NorthStarDetailed, NorthstarObjectiveLists },
})
export default class BoardGoals extends Vue {
  helpExpanded: boolean = false;
  initialLoad: boolean = false;

  currentFilter: string | null = null;

  get board() {
    return this.$store.state.board;
  }

  mounted() {
    this.initialLoad = true;
    setTimeout(() => this.initialLoad = false, 1000);
  }

  getBoardMilestoneLength(milestone: any){
    let milestones = milestone.filter((milestoneData: any)=>{
      if(milestoneData.boards.length >0 && milestoneData.boards.some((board: BoardObject) => board.pid === this.board.pid)){
        return milestone;
      }else{
        return;
      }
    });
    return milestones.length;
  }

  openNorthstarPreview(northstar: NorthStar) {
    this.$router.push({name: 'related-goal', params: {goal_id: northstar.id}});
  }

  openNorthStarAsModal(id: string) {
    this.$buefy.modal.open({
      component: NorthStarData,
      props: {
        onmodal: true,
        editable: true,
        showMilestones: true,
        board: this.$store.state.board,
        id: id
      },
      width: '780px',
      animation: 'none',
      customClass: 'northstar-detailed is-paddingless',
      // @ts-ignore
      parent: this,
    });
  }

  openAddNorthStar() {
    this.$buefy.modal.open({
      component: AddOrLinkNorthStar,
      props: {
        origin: 'board',
        inModal: true,
      },
      width: '480px',
      // @ts-ignore
      parent: this,
    });
  }

  get hasNorthStars() {
    return this.board.northStars.length >= 1;
  }

  get ongoing() {
    return this.board.northStars.filter((northStar: NorthStar) => {
      return northStar.status !== 'completed';
    })
  }
}
