





























import { Component, Vue } from 'vue-property-decorator';
import InlineDropdown from "@/components/general/InlineDropdown.vue";

@Component({
  components: {InlineDropdown},
})
export default class KeyboardShortcuts extends Vue {
  searchInput: string | null = '';
  results: Array<{ name: string, keys: Array<{ name: string }> }> = [
    { name: 'New board', keys: [{name: '⌘'}, {name: 'B'}] },
    { name: 'New objective', keys: [{name: '⇧'}, {name: '⌘'}, {name: 'C'}] },
    { name: 'New task', keys: [{name: '⇧'}, {name: '⌘'}, {name: 'C'}] },
    { name: 'Fullscreen', keys: [{name: '⇧'}, {name: '⌘'}, {name: 'C'}] },
    { name: 'Search', keys: [{name: '⇧'}, {name: '⌘'}, {name: 'C'}] },
    { name: 'Date', keys: [{name: '/'}, {name: 'd'}] },
    { name: 'Mention', keys: [{name: '@'}] },
    { name: 'Tag work', keys: [{name: '#'}] },
    { name: 'Emoji 🎉', keys: [{name: ':'}] },
  ];

  get filteredResults() {
    return this.results.filter(result => {
      return result.name!.toLowerCase().includes(this.searchInput!.toLowerCase());
    })
  }

  closeFromParent() {
    this.$emit('close', true);
  }

  mounted() {
    this.$nextTick(function(){
      //@ts-ignore ToDo: fix type issue
      this.$refs.mainInput.focus();
    });
  }
}
