


























import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class ShareUrl extends Vue {
  boardUrl: string | null = null;
  boardUrlIsCopied = false;

  @Prop({ required: true })
  boardPid!: string;

  copyBoardUrl() {
    const urlToCopy = document.querySelector('#board-url-input') as HTMLInputElement;
    if (urlToCopy != null) {
      urlToCopy.setAttribute('type', 'text');
      urlToCopy.select();
      document.execCommand('copy');
      this.boardUrlIsCopied = true;

      setTimeout(() => {
        this.boardUrlIsCopied = false;
      }, 1200);
      this.$buefy.toast.open('Link copied to clipboard')
    }
  }

  created() {
    this.boardUrl = this.$props.url;
  }
}
