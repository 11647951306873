

























































































































































































import {Component, Mixins} from "vue-property-decorator";
import {DeleteBoardHardMutation, DeleteBoardMutation, LeaveBoardMutation} from "@/graphql/board";
import BoardMembers from "@/components/board/BoardMembers.vue";
import BoardPrivacy from "@/components/board/utilities/BoardPrivacy.vue";
import KeyboardShortcuts from "@/components/general/KeyboardShortcuts.vue";
import BoardInfoEdit from "@/components/board/utilities/BoardInfoEdit.vue";
import ConfirmDelete from "@/components/general/ConfirmDelete.vue";
import {Board as BoardObject, Bookmark, NorthStar, Role} from "@/typescript/types";
import {BoardMember} from "@/typescript/types";
import isUndefined from 'lodash/isUndefined';
import {ValidationErrorParserMixin} from "@/mixins/ValidationErrorParserMixin";
import BoardNorthStars from "@/components/northstars/BoardNorthStars.vue";
import Chat from "@/components/chat/Chat.vue";
import {WorkspaceQuery} from "@/graphql/workspace";
import BoardNavigator from "@/components/board/BoardNavigator.vue";
import BoardBookmarks from "@/components/board/utilities/BoardBookmarks.vue";
import WorkableItem from "@/components/general/WorkableItem.vue";
import BoardShared from "@/components/board/utilities/BoardShared.vue";
import NorthStarData from "@/components/northstars/NorthStarData.vue";
import AddOrLinkNorthStar from "@/components/northstars/AddOrLinkNorthStar.vue";
import BoardFiles from "@/components/board/utilities/BoardFiles.vue";
import UserAvatar from "@/components/user/UserAvatar.vue";
import BoardGoals from "@/components/board/utilities/BoardGoals.vue";
import Trello from "@/components/board/thirdPartyApps/trello/Trello.vue";
import Asana from "@/components/board/thirdPartyApps/asana/Asana.vue";
import GoogleCalendar from "@/components/board/thirdPartyApps/google-calendar/GoogleCalendar.vue";
import Monday from "@/components/board/thirdPartyApps/monday/Monday.vue";
import Todoist from "@/components/board/thirdPartyApps/todoist/Todoist.vue";
import Wrike from "@/components/board/thirdPartyApps/wrike/Wrike.vue";
import BoardCompletedGoals from "@/components/board/BoardCompletedGoals.vue";
import Paywall from "@/components/subscription/Paywall.vue";



@Component({
  components: {
    BoardCompletedGoals,
    BoardGoals,
    UserAvatar,
    BoardFiles,
    BoardShared,
    WorkableItem,
    BoardBookmarks,
    BoardNavigator,
    BoardNorthStars,
    ConfirmDelete,
    BoardMembers,
    BoardPrivacy,
    KeyboardShortcuts,
    BoardInfoEdit,
    Chat
  },
})
export default class BoardSettings extends Mixins(ValidationErrorParserMixin) {
  showMoreSettings: boolean = false;
  activeTab: string | null = 'bookmarks';
  muted: boolean = false;
  showMore: boolean = false;
  uploadFiles: Array<any> = [];
  filesUploadLoading: boolean = false;
  showExpandedGoals: boolean = true;
  memberModalOpenCount: number = 0;
  isVerifyModalOpened: boolean = false;
  statisticsActive: boolean = true;
  tempOnboarding: boolean = true;

  

  get me() {
    return this.$store.state.me;
  }
  
  get newUser() {
    let userCreationDate = new Date(this.me.created_at);
    let comparisonDate = new Date('2024-02-22');
    return userCreationDate > comparisonDate;
  }

  get onGuestAccount() {
    return this.$store.state.me.isFake;
  }

  get board() {
    return this.$store.state.board;
  }

  get workspace() {
    return this.$store.state.workspace;
  }

  get currentPlan() {
    if(this.workspace.currentSubscription !== null) {
      return this.workspace.currentSubscription.plan.title
    }
    else { return null }
  }

  get isPremium() {
    if(this.$store.state.workspace.currentSubscription) {
      return true
    } else { return false }
  }

  get boardId() {
    return this.$store.state.board.id;
  }

  get onboardingStep() {
    if(this.$route.name === 'onboarding-view' && this.$route.query.step) {
      return this.$route.query.step;
    } else {
      return null;
    }
  }

  get connectedThirdPartyApps() {
    return this.me.thirdPartyProviders;
  }

  get adminMembers() {
    return this.board.members.filter((member: BoardMember) => {
      return member.role === Role.Owner;
    });
  }

  get boardMembers() {
    return this.board.members.filter((member: any)=>member.user.deleted_at === null);
  }

  get pendingInvites() {
    if(this.workspace.invites && this.workspace.invites.length > 0){
      return this.workspace.invites.filter((inv: any) => {
        if(inv.boards.length > 0 && inv.boards[0].id === this.board.id){
          return inv;
        }
      })
    }
    return [];
  }

  get ongoing() {
    return this.board.northStars.filter((northStar: NorthStar) => {
      return northStar.status !== 'completed';
    })
  }


  get hasFreemiumAccess() {
    return this.me.has_freemium_access;
  }

  get canIntegrateApps() {
    return (this.$store.state.workspace.currentSubscription && this.$store.state.workspace.currentSubscription.hasAccess && this.$store.state.workspace.currentSubscription.plan.planAccess.app_integration_access) || this.hasFreemiumAccess;
  }

 

  confirmOnboarding() {
    let objDiv = document.getElementById("settingsPanel");
    objDiv!.scrollTop = objDiv!.scrollHeight;
    setTimeout(() => {
      this.$store.commit('set_currently_onboarding_in_room', false);
    }, 1000);

  }

  toggleStatistics() {
    this.statisticsActive = !this.statisticsActive;
  }

  checkIfUserBlockedFromWorkspace(userId: string) {
    let member = this.workspace.allMembers.filter((member: any) => {
      return member.user.id === userId;
    });


    if(member[0].access_workspace == 0) {
      return false;
    }else{
      return true;
    }
  }




  openAddNorthStar() {
    this.$buefy.modal.open({
      component: AddOrLinkNorthStar,
      props: {
        origin: 'board',
        inModal: true,
      },
      width: '480px',
      // @ts-ignore
      parent: this,
    });
  }

  openNorthStarAsModal(id: string) {
    this.$buefy.modal.open({
      component: NorthStarData,
      props: {
        onmodal: true,
        editable: true,
        showMilestones: true,
        board: this.$store.state.board,
        id: id
      },
      width: '780px',
      animation: 'none',
      customClass: 'northstar-detailed is-paddingless',
      // @ts-ignore
      parent: this,
    });
  }

  openBoardInfoEdit() {
    this.$buefy.modal.open({
      component: BoardInfoEdit,
      width: '480px',
      parent: this,
    });
  }

  openBoardMembers() {
    this.$buefy.modal.open({
      component: BoardMembers,
      width: '480px',
      parent: this,
      props: {
        fromMemberInviteClick: true
      }
    }); 
  }

  handleAppClick() {
    if(this.canIntegrateApps) {
      this.$router.push({name: 'board-apps'});
    } else {
      this.openPaywall();
    }

    
  }

  openDeleteModal(type: string) {
    let deleteItemName = '';
    let deleteParagraph = '';
    if(this.$store.state.workspace.boards.length === 1)
    {
      this.$buefy.toast.open({
        message: "You can't delete the last room in the workspace",
        position: "is-bottom-right",
        type: "is-warning",
      });
      return;
    }

    if(type == 'soft'){
      deleteItemName = 'Are you sure you want to archive this room?'
      deleteParagraph = 'We\'ll archive all content related to this. You can recover it latter.'
    }else{
      deleteItemName = 'Are you sure you want to delete this room?'
      deleteParagraph = 'You\'ll lose all content related to this. We can\'t recover it once you confirm this action.'
    }

    this.$buefy.modal.open({
      component: ConfirmDelete,
      props: {
        deleteItemName: deleteItemName,
        deleteParagraph: deleteParagraph
      },
      events: {
        //@ts-ignore ToDo: fix type issue
        'confirmed': value => {
          if (value === true) {
            if(type == 'soft'){
              this.deleteBoard(this.$store.state.board.id);
            }else{
              this.deleteBoardHard(this.$store.state.board.id);
            }

          }
        }
      },
      width: '500px',
      parent: this,
    });
  }

  leaveBoard() {
    if (!this.isAbleToLeaveBoard()) {
      this.$buefy.toast.open({
        message: "You cannot leave the board as you are the only owner. Please assign another user as owner",
        position: "is-bottom-right",
        type: "is-warning",
      });
      return;
    }
    let id = this.board.id
    let haveExtraBoard = this.$store.state.workspace.boards.find((board: BoardObject) => {
      return board.id !== id
    });
    if (isUndefined(haveExtraBoard)) {
      this.$buefy.toast.open({
        message: "Create a new board to leave this board",
        position: "is-bottom-right",
        type: "is-warning",
      });
      return;
    }

    this.$store.dispatch('delete_board', id);
    this.$store.commit('set_active_board_pid', haveExtraBoard.pid);
    this.$router.push({name: 'workspace-layout'});
    this.$apollo.mutate({
      mutation: LeaveBoardMutation,
      variables: {
        id: id,
      },
    }).catch((error) => {
      this.parseValidationError(error);
      this.$buefy.toast.open({
        message: this.fieldValidationMessage('leaveBoard'),
        position: "is-bottom-right",
        type: "is-warning",
      });

    })
  }

  deleteBoard(id: string) {

    //this.$store.dispatch('delete_board', id);

    this.$apollo
      .mutate({
        mutation: DeleteBoardMutation,
        variables: {
          id: id,
        },
        refetchQueries: [{query: WorkspaceQuery, variables: {id: this.$store.state.workspace.id}}]
      })
      .catch((_error) => {
       
      })
      .finally(() => {
        this.$router.push({
          name: 'workspace-layout'
        });
        this.$buefy.toast.open({
          message: 'Deleted successfully',
          position: "is-bottom-right",
          type: "is-black",
        });
      })
    ;
  }

  deleteBoardHard(id: string) {

    this.$apollo
      .mutate({
        mutation: DeleteBoardHardMutation,
        variables: {
          id: id,
        },
        refetchQueries: [{query: WorkspaceQuery, variables: {id: this.$store.state.workspace.id}}]
      })
      .catch((_error) => {
      
      })
      .finally(() => {
        this.$router.push({
          name: 'workspace-layout'
        });
        this.$buefy.toast.open({
          message: 'Deleted successfully',
          position: "is-bottom-right",
          type: "is-black",
        });
      })
    ;
  }

  isAbleToLeaveBoard() {
    return this.adminMembers.length == 1 && this.adminMembers[0].user.id === this.me.id ? false : true;
  }

  openThirdPartyModal(name: string, id: string) {
    let lowerName = name.toLowerCase();
    switch (lowerName) {
      case 'trello':
        this.$buefy.modal.open({
          component: Trello,
          width: '480px',
          props: {boards: this.workspace.boards, providerId: id},
          // @ts-ignore
          parent: this,
        });
        break;
      case 'asana':
        this.$buefy.modal.open({
          component: Asana,
          width: '480px',
          props: {boards:this.workspace.boards, providerId: id},
          // @ts-ignore
          parent: this,
        });
        break;
      case 'google calendar':
        this.$buefy.modal.open({
          component: GoogleCalendar,
          width: '480px',
          props: {boards:this.workspace.boards, providerId: id},
          // @ts-ignore
          parent: this,
        });
        break;
      case 'monday.com':
        this.$buefy.modal.open({
          component: Monday,
          width: '480px',
          props: {boards:this.workspace.boards, providerId: id},
          // @ts-ignore
          parent: this,
        });
        break;
      case 'todoist':
        this.$buefy.modal.open({
          component: Todoist,
          width: '480px',
          props: {boards:this.workspace.boards, providerId: id},
          // @ts-ignore
          parent: this,
        });
        break;
      case 'wrike':
        this.$buefy.modal.open({
          component: Wrike,
          width: '480px',
          props: {boards:this.workspace.boards, providerId: id},
          // @ts-ignore
          parent: this,
        });
        break;
    }
  }

  mounted(){
    this.$events.listen('bookmark-added', eventData => {
      if(this.board.pid === eventData.board_pid) {
        let index = this.board.bookmarks.findIndex((bookmark: Bookmark) => bookmark.id === eventData.bookmark.id);
        if(index == -1) {
          this.board.bookmarks.push(eventData.bookmark);
        }
      }

    })
    this.$events.listen('verify-modal-opened', (_) => {
      this.isVerifyModalOpened = true;
    })

    if(this.$store.state.openBoardMemberModal)
    {
      setTimeout(() => { this.openBoardMembers() }, 2000);
      this.$store.commit('set_open_board_member_modal', false);
    }

    this.$events.listen('open_board_member', () => {
      setTimeout(() => { this.openBoardMembers() }, 2000);
      this.$store.commit('set_open_board_member_modal', false);
    })
  }

  openPaywall() {
    this.$buefy.modal.open({
      component: Paywall,
      // @ts-ignore
      customClass: 'split-modal',
      parent: this,
    });
  }
}
