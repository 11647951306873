

























import {Component, Vue} from 'vue-property-decorator';
import {NorthStar} from "@/typescript/types";
import NorthStarData from "@/components/northstars/NorthStarData.vue";

@Component({
  components: {},
})
export default class BoardCompletedGoals extends Vue {
  get board() {
    return this.$store.state.board;
  }

  get completed() {
    return this.board.northStars.filter((northStar: NorthStar) => {
      return northStar.status === 'completed';
    })
  }

  openNorthStarAsModal(id: string) {
    this.$buefy.modal.open({
      component: NorthStarData,
      props: {
        onmodal: true,
        editable: true,
        showMilestones: true,
        board: this.$store.state.board,
        id: id
      },
      width: '780px',
      animation: 'none',
      customClass: 'northstar-detailed is-paddingless',
      // @ts-ignore
      parent: this,
    });
  }
}
