




























import {Component, Vue} from 'vue-property-decorator';
import BoardInfoEdit from "@/components/board/utilities/BoardInfoEdit.vue";
import BoardPrivacy from "@/components/board/utilities/BoardPrivacy.vue";
import BoardSettingsDropdown from "@/components/board/utilities/BoardSettingsDropdown.vue";

@Component({
  components: {BoardSettingsDropdown, BoardPrivacy},
})
export default class BoardNavigator extends Vue {
  allowDescriptionVisible: boolean = false;

  get board() {
    return this.$store.state.board;
  }

  openBoardPrivacy() {
    this.$buefy.modal.open({
      component: BoardPrivacy,
      props: {
        open: true
      },
      width: '480px',
      parent: this,
    });
  }

  openBoardInfoEdit() {
    this.$buefy.modal.open({
      component: BoardInfoEdit,
      width: '480px',
      parent: this,
    });
  }
}
