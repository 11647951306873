











import {Component, Vue} from 'vue-property-decorator';
import {WorkspaceNotificationPaginator} from '@/typescript/types';
// import Encryption from '@/encryption'
//
// import {
//   BoardNotificationCreatedSubscription,
//   BoardNotificationsQuery
// } from "@/graphql/BoardNotification";
// import {ReadAllBoardNotificationsMutation} from "@/graphql/board";
import {
  GetAllWorkspaceNotificationForUser,
  ReadAllWorkspaceNotificationsForBoard,
  WorkspaceNotificationsForBoardQuery
} from "@/graphql/WorkspaceNotification";
//import Encryption from "@/encryption";

@Component({
  components: {},
  apollo: {
    getWorkspaceNotificationsForBoard: {
      query: WorkspaceNotificationsForBoardQuery,
      fetchPolicy: 'network-only',
      result(result) {
        if(result.data.getWorkspaceNotificationsForBoard.data.length > 0) {
          this.enableNotification = true;
        }
        return result.data.getWorkspaceNotificationsForBoard;
      },
      // subscribeToMore: {
      //   document: WorkspaceNotificationCreatedSubscription,
      //   variables() {
      //     return {
      //       workspace_id: this.$store.state.workspace.id,
      //       board_id: this.board.id
      //     }
      //   },
      //   updateQuery(previous, {subscriptionData}) {
      //     let boardNotification = subscriptionData.data.workspaceNotificationCreated;
      //     let message = subscriptionData.data.workspaceNotificationCreated.data.message;
      //     this.requestNotificationAccess();
      //
      //     if (this.board.id !== this.activeBoardPid) {
      //       message = Encryption.decrypt(message);
      //       this.triggerNotification(message)
      //     }
      //
      //     //this.$store.commit('add_board_notification', boardNotification)
      //
      //     return {
      //       getWorkspaceNotificationsForBoard: {
      //         data: [boardNotification,...previous.getWorkspaceNotificationsForBoard.data],
      //         paginatorInfo: previous.getWorkspaceNotificationsForBoard.paginatorInfo,
      //         __typename: previous.getWorkspaceNotificationsForBoard.__typename,
      //       },
      //     };
      //   },
      // },
      variables() {
        return {
          id: this.board.id,
        };
      },
    }
  }
})

export default class BoardChatIcon extends Vue {
  getWorkspaceNotificationsForBoard: WorkspaceNotificationPaginator | null = null;
  enableNotification = false;
  timer: any ;
  setInterval = 5000;


  get board() {
    return this.$store.state.board;
  }

  get activeBoardPid() {
    return this.$store.state.active_board_pid;
  }

  clicked() {
    if (this.$route.name === 'chat') {
      this.$router.push({name: 'board-layout'});
    } else {
      this.$router.push({name: 'chat'});
      this.$emit('toggle', true);
      this.markAsRead()
    }
  }

  markAsRead() {
    // if (this.getWorkspaceNotificationsForBoard && this.getWorkspaceNotificationsForBoard.data) {
    //   this.getWorkspaceNotificationsForBoard.data = [];
    // }
    // this.$events.fire('removeNotification');
    // this.enableNotification = false;
    this.$apollo.mutate({
      mutation: ReadAllWorkspaceNotificationsForBoard,
      variables: {
        id: this.board.id
      },
      refetchQueries: [{query: GetAllWorkspaceNotificationForUser, variables: {id: this.$store.state.workspace.id}}],
    })
  }

  playSound() {
    if(this.$store.state.mute_notification_sound) {
      let audio = new Audio(require('@/assets/audio/chat-message.mp3'));
      audio.volume = 0.2;
      audio.play();
    }

  }

  requestNotificationAccess() {
    if (Notification.permission !== "denied" && Notification.permission != 'granted') {
      this.$events.fire('open-browser-notofication-permission')
    }
  }

  triggerNotification(message: string) {
    if (!("Notification" in window)) {
      return
    }

    if (Notification.permission === "granted") {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        new Notification(message);
        this.playSound();
      }, this.setInterval);
    }
    document.title = "You have unread messages...";
  }

  mounted() {
    this.$events.listen('board-notification', eventData => {
      if(eventData.data.board.id == this.board.id && eventData.data.read == false) {
        this.enableNotification = true;
      }
    })
  }
}
