










































































import {Component, Mixins, Prop} from 'vue-property-decorator';
import MasterLayout from "@/components/layout/MasterLayout.vue";
import Template from "@/components/templates/Template.vue";
import {ValidationErrorParserMixin} from "@/mixins/ValidationErrorParserMixin";
import {CopyAttachments, DeleteAttachments} from "@/graphql/attachment";
import {AddBoardAttachments} from "@/graphql/board";
import { User} from "@/typescript/types";
import {WorkspaceQuery} from "@/graphql/workspace";
import {BoardAttachments as BA} from "@/graphql/attachment";

@Component({
  components: {Template, MasterLayout},
})
export default class BoardAttachments extends Mixins(ValidationErrorParserMixin) {

  sliceAmount: number = 3;
  moreAmount: boolean = false;
  uploadFiles: Array<any> = [];
  filesUploadLoading: boolean = false;

  @Prop({default: true, required: false})
  inModal!: boolean;

  selectedSuggestions: Array<string> = [];

  get isOwner() {
    let owner = this.$store.state.board.owner.find((user: User) => user.id == this.$store.state.me.id);
    if (owner) {
      return true;
    }
    return false;
  }

  get attachments() {
    return this.$store.state.board.attachments;
  }

  get isDisabled() {
    return (this.uploadFiles && this.uploadFiles.length > 0) ? false : true;
  }

  get attachmentSuggestion() {
    if(this.$store.state.board.board_template && this.$store.state.board.board_template.attachments.length > 0) {
      return this.$store.state.board.board_template.attachments.filter((attachment: any) => {
        if(!this.selectedSuggestions.includes(attachment.id)) {
          return attachment;
        }
      })
    }
    return null;
  }

  deleteUploadFiles(index: any) {
    this.uploadFiles.splice(index, 1);
  }

  toggleAmount() {
    if(!this.moreAmount) {
      this.sliceAmount = this.attachments.length;
      this.moreAmount = true;
    } else {
      this.sliceAmount = 3;
      this.moreAmount = false;
    }
  }

  getAttachment(fileName: string, folder: string) {
    //@ts-ignore
    return this.$attachmentsLinks.file_path[folder] + fileName;
  }

  uploadAttachments(files: any) {
    this.filesUploadLoading = true;
    this.$apollo.mutate({
      mutation: AddBoardAttachments,
      variables: {
        board_id: this.$store.state.board.id,
        workspace_id: this.$store.state.workspace.id,
        files: files
      },
      refetchQueries:[{query: WorkspaceQuery, variables:{pid: this.$store.state.workspace.pid}}, {query: BA, variables:{board_id: this.$store.state.board.id}}]
    }).then((_res: any) => {
      this.filesUploadLoading = false;

      this.$buefy.toast.open({
        message: 'Added!',
        position: 'is-bottom-right',
        type: 'is-black',
      });
      //@ts-ignore
      this.$parent.close();
      this.uploadFiles = [];
    }).catch((errors) => {
      this.filesUploadLoading = false;
      this.parseValidationError(errors);
      for (const [key, value] of Object.entries(this.arrayValidationMessages()!!)) {
        let index = key.split('.');
        this.$buefy.toast.open({
          //@ts-ignore
          message: this.uploadFiles[index[1]].name + ' -- ' + value,
          position: "is-bottom-right",
          type: "is-danger",
          duration: 5000
        });
      }
      this.uploadFiles = [];
    })
  }

  deleteAttachment(attachment_id: string) {
    let index = this.attachments!!.findIndex((attachment: any) => attachment.id === attachment_id);
    if (index != -1) {
      this.attachments!!.splice(index, 1);
    }
    this.$apollo.mutate({
      mutation: DeleteAttachments,
      variables: {
        attachmentId: attachment_id
      },
      refetchQueries:[{query: WorkspaceQuery, variables:{pid: this.$store.state.workspace.pid}}]
    }).then(_ => {
      this.$buefy.toast.open({
        message: 'Deleted!',
        position: 'is-bottom-right',
        type: 'is-black',
      });
    })
  }

  async downloadFile(url: string, name: string) {
    let blob = await fetch(url).then(r => r.blob());
    var fileURL = window.URL.createObjectURL(blob);
    var fURL = document.createElement('a');

    fURL.href = fileURL;
    fURL.setAttribute('download', name);
    document.body.appendChild(fURL);

    fURL.click();
  }

  dragover(event: any) {
    event.preventDefault();
    /**
     * Add some visual fluff to show the user can drop its files
     */
    if (!event.currentTarget.classList.contains('filemanager-drag-drop')) {
      event.currentTarget.classList.add('currently-dropping');
    }
  }

  dragleave(event: any) {
    /**
     * Clean up
     */
    event.currentTarget.classList.remove('currently-dropping');
  }

  drop(event: any) {
    event.preventDefault();
    this.uploadAttachments(event.dataTransfer.files);
    event.currentTarget.classList.remove('currently-dropping');
  }

  copyAttachment(attachment_id: string) {
    this.selectedSuggestions.push(attachment_id);
    this.$apollo.mutate({
      mutation: CopyAttachments,
      variables: {
        attachment_id: attachment_id,
        board_id: this.$store.state.board.id,
        workspace_id: this.$store.state.workspace.id
      },
      refetchQueries:[{query: WorkspaceQuery, variables:{pid: this.$store.state.workspace.pid}},{query: BA, variables:{board_id: this.$store.state.board.id}}]
    }).then((_) => {
      this.$buefy.toast.open({
        message: 'Added!',
        position: 'is-bottom-right',
        type: 'is-black',
      });
    })
  }

}
